import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const FaqItemWrapper = styled.div`
  & .title {
    padding: 15px 0;
    cursor: pointer;
    transition: all 0.4s;
    font-size: 20px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      opacity: 0.7;
    }
    & .arrow {
      font-size: 24px;
      margin: 0 12px;
    }
  }
  & .description-wrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &.visible {
      max-height: 1000px;
      transition: max-height 1s ease-in-out;
    }
    & .description {
      padding-bottom: 15px;
    }
  }
  & + & {
    border-top: 1px solid #cecece;
  }
`;

const FaqItem = ({
  title,
  description,
  active = false,
  onClick = () => {},
}) => {
  return (
    <FaqItemWrapper>
      <div className="title" onClick={onClick}>
        {title}
        <span className="arrow">{active ? "+" : "-"}</span>
      </div>
      <div className={`description-wrapper ${active ? "visible" : ""}`}>
        <div className="description">{description}</div>
      </div>
    </FaqItemWrapper>
  );
};

/**
 * items: {title: string; description: string;}[];
 */

const FaqList = ({ items = [] }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  return (
    <Container>
      {items.map((item, index) => (
        <FaqItem
          key={item.title}
          {...item}
          active={index === activeIndex}
          onClick={() => {
            if (activeIndex === index) {
              setActiveIndex(-1);
            } else {
              setActiveIndex(index);
            }
          }}
        />
      ))}
    </Container>
  );
};

export default FaqList;
