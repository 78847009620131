import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { device } from "../../utils";

import OpenImg from "../../assets/image/svg/open.svg";
import DecentralizedImg from "../../assets/image/svg/decentralised.svg";
import InterImg from "../../assets/image/svg/interoperation.svg";
import NeutralImg from "../../assets/image/svg/neutral.svg";
import PerformantImg from "../../assets/image/svg/performant.svg";
import OnPremImg from "../../assets/image/svg/on-prem.svg";

const Title = styled.div`
  font-size: 24px;
  color: black;
  margin: 40px 0;
  @media ${device.sm} {
    font-size: 32px;
    margin: 70px 0;
  }
  @media ${device.md} {
    font-size: 43px;
    margin: 90px 0;
  }
  @media ${device.lg} {
    font-size: 53px;
    margin: 120px 0;
  }
`;

const BottomSpace = styled.div`
  margin-bottom: 40px;
  @media ${device.sm} {
    margin-bottom: 70px;
  }
  @media ${device.md} {
    margin-bottom: 90px;
  }
  @media ${device.lg} {
    margin-bottom: 120px;
  }
`;

const ItemWrapper = styled.div`
  max-width: 367px;
`;

const ItemImg = styled.img`
  height: 80px;
`;

const ItemTitle = styled.p`
  color: black;
  font-weight: bold;
  margin: 20px 0;
`;

const ItemDescription = styled.div`
  color: black;
  margin-bottom: 40px;
`;

const Testimonial = () => {
  return (
    <>
      {/* <!-- testimonial section --> */}

      <Container>
        <Row className="justify-content-center mb-4">
          <Col lg="12">
            <div className="text-center">
              <Title>Enabling open and neutral NFT Markets </Title>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="12" sm="6" md="4" lg="4">
            <ItemWrapper>
              <ItemImg alt="img" src={OpenImg} />
              <ItemTitle>Open</ItemTitle>
              <ItemDescription>
                Protocols built with Web3 infrastructure and driven by a global
                community of developers and technologists.
              </ItemDescription>
            </ItemWrapper>
          </Col>
          <Col xs="12" sm="6" md="4" lg="4">
            <ItemWrapper>
              <ItemImg alt="img" src={DecentralizedImg} />
              <ItemTitle>Decentralised</ItemTitle>
              <ItemDescription>
                Designed to operate as hybrid or P2P, standing on the
                shoulders of giants such as IPFS or 0xProtocol
              </ItemDescription>
            </ItemWrapper>
          </Col>
          <Col xs="12" sm="6" md="4" lg="4">
            <ItemWrapper>
              <ItemImg alt="img" src={InterImg} />
              <ItemTitle>Interoperable</ItemTitle>
              <ItemDescription>
                Future-proof system following multi-chain Ethereum/EVM
                standards (ERC721, ERC1155, OpenSea compatibility, etc)
              </ItemDescription>
            </ItemWrapper>
          </Col>
          <Col xs="12" sm="6" md="4" lg="4">
            <ItemWrapper>
              <ItemImg alt="img" src={NeutralImg} />
              <ItemTitle>Neutral</ItemTitle>
              <ItemDescription>
                Multi-chain. Community-driven and operating with a DAO model.
              </ItemDescription>
            </ItemWrapper>
          </Col>
          <Col xs="12" sm="6" md="4" lg="4">
            <ItemWrapper>
              <ItemImg alt="img" src={PerformantImg} />
              <ItemTitle>Performant</ItemTitle>
              <ItemDescription>
                Off-chain matching and on-chain settlement on high-throughput
                chains with subsecond global finality.
              </ItemDescription>
            </ItemWrapper>
          </Col>
          <Col xs="12" sm="6" md="4" lg="4">
            <ItemWrapper>
              <ItemImg alt="img" src={OnPremImg} />
              <ItemTitle>Niche-focused</ItemTitle>
              <ItemDescription>
                Markets built to cater the needs of specific industries, and with focused communities and incentive systems.
              </ItemDescription>
            </ItemWrapper>
          </Col>
        </Row>
        <BottomSpace />
      </Container>
    </>
  );
};

export default Testimonial;
