import React from "react";
import styled from "styled-components";

import ColorSvg from "../../../assets/image/svg/color-bar.svg";
import { device } from "../../../utils";

const ColorDividerWrapper = styled.div`
  height: 60px;
  background-image: url(${ColorSvg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media ${device.sm} {
    height: 90px;
  }
  @media ${device.md} {
    height: 137px;
  }
`;

export const ColorDivider = () => {
  return <ColorDividerWrapper />;
};
