import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  padding-top: 0;
`;

const Title = styled.h2`
  font-size: 24px;
  color: black;
  font-weight: 500;
  margin-bottom: 16px;
  @media ${device.md} {
    font-size: 32px;
    margin-bottom: 24px;
  }
  @media ${device.lg} {
    font-size: 36px;
    margin-bottom: 32px;
  }
  @media ${device.xl} {
    font-size: 42px;
    margin-bottom: 40px;
  }
`;

const ItemWrapper = styled.div`
  height: 80px;
  padding-left: 80px;
  background-color: #a5a5a5;
  @media ${device.md} {
    height: 70px;
    padding-left: 70px;
  }
  @media ${device.lg} {
    height: 100px;
    padding-left: 100px;
  }
  @media ${device.xl} {
    height: 120px;
    padding-left: 120px;
  }
`;

const ItemContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px 10px 10px 14px;

  p {
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  span {
    color: white;
  }
  a {
    cursor: pointer;
    color: white !important;
  }
  a,
  span {
    align-self: flex-end;
    font-size: 13px;
  }

  &.gaming {
    background-color: #5a40db;
  }
  &.music {
    background-color: #0cf490;
    p,
    a,
    span {
      color: black;
    }
  }
  &.fashion {
    background-color: #021b26;
  }
  @media ${device.md} {
    padding: 10px 6px 6px 10px;
    p {
      font-size: 18px;
    }
    a,
    span {
      font-size: 14px;
    }
  }
  @media ${device.xl} {
    padding: 22px 17px 17px 22px;
    p {
      font-size: 20px;
    }
    a,
    span {
      font-size: 16px;
    }
  }
`;

const Market = () => {
  return (
    <>
      {/* <!-- Content section --> */}
      <SectionStyled>
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg="9">
              <div className="text-center">
                <Title>Helping Web3 go mainstream</Title>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col
              lg="4"
              md="4"
              sm="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ItemWrapper>
                <ItemContentWrapper className="gaming">
                  <p>Gaming</p>
                  <a href="https://www.gameswap.org" target="_blank" >Visit Gameswap &gt;</a>
                </ItemContentWrapper>
              </ItemWrapper>
            </Col>
            <Col
              lg="4"
              md="4"
              sm="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ItemWrapper>
                <ItemContentWrapper className="music">
                  <p>Music</p>
                  <span>(TBA)</span>
                </ItemContentWrapper>
              </ItemWrapper>
            </Col>
            <Col
              lg="4"
              md="4"
              sm="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ItemWrapper>
                <ItemContentWrapper className="fashion">
                  <p>Fashion</p>
                  <span>(TBA)</span>
                </ItemContentWrapper>
              </ItemWrapper>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Market;
