import React from "react";
import Hero from "../sections/home/Hero";
import Testimonial from "../sections/home/Testimonial";
import Feature from "../sections/home/Feature";
import Content from "../sections/home/Content";
import Fact from "../sections/home/Fact";
import Reviews from "../sections/home/Reviews";
import Author from "../sections/home/Author";
import Pricing from "../sections/home/Pricing/Pricing";
import Offer from "../sections/home/Offer";
import Faq from "../sections/home/Faq";
import CTA from "../sections/home/CTA";
import Market from "../sections/home/Market";
import PageWrapper from "../components/PageWrapper";
import FaqList from "../components/FaqList/FaqList";
import { ColorDivider } from "../components/Divider";

const HomePage = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <ColorDivider />
        <Testimonial />
        <Market />
        {/* <Feature />
        <Content />
        <Offer />
        <Fact />
        <Reviews />
        <Author />
        <Pricing />
        <Faq />
        <CTA /> */}
      </PageWrapper>
    </>
  );
};
export default HomePage;
