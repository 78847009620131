import React, { useEffect } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container } from "react-bootstrap";

import { Section, Box } from "../../components/Core";

import YahooImg from "../../assets/image/svg/yahoo_finance.svg";
import DelphiImg from "../../assets/image/svg/delphi.svg";
import CoinDeskImg from "../../assets/image/svg/coindesk.svg";
import { device } from "../../utils";

const HeroWrapper = styled.div`
  min-height: 100vh;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentWrapper = styled(Box)`
  max-width: 670px;
`;

const Title = styled.h2`
  color: black;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  @media ${device.sm} {
    font-size: 55px;
  }
  @media ${device.md} {
    font-size: 70px;
  }
`;

const Description = styled.p`
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin-top: 30px;
  @media ${device.sm} {
    font-size: 17px;
    margin-top: 40px;
  }
  @media ${device.md} {
    font-size: 22px;
    margin-top: 50px;
  }
`;

const FeaturedIn = styled.p`
  color: black;
  font-size: 13px;
  font-weight: 400;
  margin-top: 40px;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FeatureImg = styled.img`
  height: 24px;
  & + & {
    margin-left: 24px;
  }
  @media ${device.sm} {
    height: 32px;
    & + & {
      margin-left: 36px;
    }
  }
  @media ${device.md} {
    height: 40px;
    & + & {
      margin-left: 50px;
    }
  }
`;

const Hero = () => {
  useEffect(() => {
    const waitSecond = () =>
      new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });

    const enableDotsEffect = async () => {
      let effectAdded = false;
      while (!effectAdded) {
        await waitSecond();
        if (window.VANTA !== undefined && window.THREE !== undefined) {
          window.VANTA.DOTS({
            el: document.getElementById("home-hero"),
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: 0x0,
            color2: 0xffffff,
            backgroundColor: 0xffffff,
            size: 2.0,
            spacing: 42.0,
          });
          effectAdded = true;
        }
      }
    };
    enableDotsEffect();
  }, []);
  return (
    <HeroWrapper id="home-hero">
      <Section className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <ContentWrapper>
            <Title>The global layer for NFTs exchange</Title>
            <Description>
              Niftyx is deploying protocols and apps for
              niche-focused global NFT markets ensuring a neutral
              playing field for creators, users and firms.
            </Description>
            <FeaturedIn>FEATURED IN</FeaturedIn>
            <FeaturesWrapper>
              <FeatureImg alt="img" src={YahooImg} />
              <FeatureImg alt="img" src={DelphiImg} />
              <FeatureImg alt="img" src={CoinDeskImg} />
            </FeaturesWrapper>
          </ContentWrapper>
          <div className="pt-5"></div>
        </Container>
      </Section>
    </HeroWrapper>
  );
};

export default Hero;
